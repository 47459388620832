import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { COLORS } from "../../../SupportingFiles/colors";
import "./../../../SCSS/ResponsivePages.scss";

function TermsCondition() {
  const isMobile = useIsMobile();
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="cmsheader tc">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} marginBottom={"24px"}>
            <h2 style={{ textAlign: "center" }}>Terms & Conditions</h2>
          </Grid>
        </Grid>
      </Box>

      <Box className="textContent-pages">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} marginBottom={"24px"}>
            <h5 style={{ marginBottom: "12px" }}>Terms of Use Agreement</h5>
            <p>
              This Agreement is entered into by and between SWASTIKA INSURANCE
              BROKING SERVICES LIMITED (CIN - U66000MP2009PLC021881), a company
              incorporated under the Companies Act, 1956, having its registered
              office at 48 Jaora Compound, MYH Road, Indore, Madhya
              Pradesh-452001, and corporate office at A-26/1, DLF Shopping Mall,
              DLF Phase 1, DT mega mall ,Gurugram -122001, Haryana (hereinafter
              referred to as the “Company”), and the Vendor (hereinafter
              referred to as the “Service Provider”).
            </p>
            <p>
              The Company and the Service Provider are collectively referred to
              as the “Parties” and individually as a “Party.
            </p>
          </Grid>

          <Grid xs={12} marginBottom={"24px"}>
            <h5 style={{ marginBottom: "12px" }}>RECITALS</h5>
            <ul className="content-listpoints">
              <li>
                The Service Provider specializes in providing marketing and
                advertising services and possesses the necessary experience,
                expertise, manpower, and infrastructure to deliver the required
                services to the Company
              </li>
              <li>
                Based on the representations made by the Service Provider, the
                Company wishes to engage the Service Provider to provide various
                services under the terms and conditions set forth in this
                Agreement
              </li>
            </ul>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h5>
              NOW, THEREFORE, IN CONSIDERATION OF THE MUTUAL COVENANTS AND
              PROMISES CONTAINED HEREIN, THE PARTIES AGREE AS FOLLOWS:
            </h5>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h6 style={{ marginBottom: "5px" }}>
              1. APPOINTMENT OF SERVICE PROVIDER
            </h6>
            <p>
              We may provide various open communication tools on our website,
              such as blog comments, blog posts, public chat, forums, message
              boards, newsgroups, product ratings and reviews, various social
              media services, etc. You understand that we do not pre-screen
              content posted by users of these various communication tools,
              which means that if you choose to use these tools to submit any
              type of content to our website (“user generated content”), then it
              is your personal responsibility to use these tools in a
              responsible and ethical manner. By posting information or
              otherwise using any open communication tools as mentioned, you
              agree that you will not upload, post, share, or otherwise
              distribute any content that:
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h6 style={{ marginBottom: "5px" }}>2. COMPENSATION</h6>
            <p>
              The Company agrees to compensate the Service Provider for the
              services rendered, as per the terms and conditions mutually agreed
              upon in writing by the Parties.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h6 style={{ marginBottom: "5px" }}>
              3.OBLIGATIONS OF THE SERVICE PROVIDER
            </h6>
            <ul className="content-listpoints">
              <li>
                The Service Provider shall deliver the services within the
                agreed timeframe and in compliance with the stipulated terms
              </li>
              <li>
                Services shall be rendered diligently, professionally, and
                ethically, adhering to the highest standards of care, expertise,
                and skill. The Service Provider will also perform any ancillary
                tasks reasonably requested by the Company.
              </li>
              <li>
                The Service Provider shall not assign, commit, or obligate any
                person or entity on behalf of the Company without prior written
                consent.
              </li>
              <li>
                The Service Provider shall comply with all applicable laws, pay
                any required fees, and maintain all necessary licenses and
                permits to perform its obligations under this Agreement.
              </li>
              <li>
                The Service Provider guarantees that the services provided will
                meet the Company’s satisfaction.
              </li>
              <li>
                The Service Provider warrants that all information provided to
                the Company is true, accurate, complete, and up-to-date.
              </li>
              <li>
                If applicable, the Service Provider shall comply with the Goods
                & Services Tax (GST) regulations, ensuring that the Company
                receives all relevant benefits.
              </li>
            </ul>
          </Grid>

          <Grid xs={12} marginBottom={"24px"}>
            <h6 style={{ marginBottom: "12px" }}>
              4. OBLIGATIONS OF THE COMPANY
            </h6>
            <ul className="content-listpoints">
              <li>
                The Company shall comply with all applicable laws, pay the
                necessary fees, and maintain all required licenses and permits
                to fulfill its obligations under this Agreement.
              </li>
              <li>
                The Company will provide all necessary information and documents
                to the Service Provider to facilitate the delivery of services
                in accordance with the agreed terms.
              </li>
            </ul>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h6 style={{ marginBottom: "12px" }}>
              5. REPRESENTATIONS AND WARRANTIES
            </h6>
            <p>Each Party warrants and represents to the other that:</p>
            <ul className="content-listpoints">
              <li>
                It is duly organized and validly existing under the laws of its
                jurisdiction.
              </li>
              <li>
                Its obligations under this Agreement are legal, valid, binding,
                and enforceable.
              </li>
              <li>
                The transactions contemplated herein do not breach any laws,
                contracts, or organizational documents and do not require any
                governmental approval.
              </li>
            </ul>
          </Grid>

          <Grid xs={12} marginBottom={"24px"}>
            <h6 style={{ marginBottom: "12px" }}>6. INDEMNIFICATION</h6>
            <p>
              The Service Provider agrees to indemnify and hold harmless the
              Company and its officers and employees from all expenses, costs,
              claims, demands, liabilities, and damages, including reasonable
              attorney’s fees, arising from any unauthorized or negligent act or
              omission by the Service Provider or its employees.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h6 style={{ marginBottom: "12px" }}>
              7. TERMINATION AND CONSEQUENCES
            </h6>
            <ul className="content-listpoints">
              <li>
                Either Party may terminate this Agreement under the following
                conditions:
                <ol>
                  <li>
                    Immediately, if the other Party is declared insolvent or
                    bankrupt.
                  </li>
                  <li>
                    Immediately, if the other Party is dissolved or wound up.
                  </li>
                  <li>
                    Upon material breach by the other Party, after giving 15
                    days’ notice to rectify the breach, if not rectified within
                    the specified time.
                  </li>
                </ol>
              </li>
              <li>
                Either Party may terminate the Agreement for convenience by
                giving 30 days’ prior written notice.
              </li>
              <li>
                Termination does not relieve any Party of obligations or
                liabilities accrued prior to or after the termination date.
              </li>
            </ul>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h6 style={{ marginBottom: "12px" }}>8. CONFIDENTIALITY</h6>
            <p>
              The Service Provider shall use confidential information solely for
              the purpose of rendering services. Upon termination or at the
              Company’s request, the Service Provider shall return or destroy
              all confidential information and confirm such actions in writing.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h6 style={{ marginBottom: "12px" }}>
              9. INTELLECTUAL PROPERTY RIGHTS
            </h6>
            <ul className="content-listpoints">
              <li>
                The Service Provider and its employees shall not use the
                Company’s name, logo, or any related intellectual property
                without prior written consent.
              </li>
              <li>
                Each Party retains ownership of its pre-existing intellectual
                property.
              </li>
              <li>
                The Parties shall not use or infringe upon each other’s
                intellectual property rights or those of any third party without
                consent.
              </li>
            </ul>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h6 style={{ marginBottom: "12px" }}>
              10.GOVERNING LAW AND JURISDICTION
            </h6>
            <p>
              This Agreement shall be governed by and construed in accordance
              with the laws of India. Any disputes shall be subject to the
              jurisdiction of the courts in Gurugram, Haryana, India.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h6 style={{ marginBottom: "12px" }}>
              11. MISCELLANEOUS PROVISIONS
            </h6>
            <ul className="content-listpoints">
              <li>
                <span>Independent Contractor:</span> The Service Provider agrees
                to perform its services as an independent contractor. This
                Agreement does not create an employer-employee or joint venture
                relationship between the Parties
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default TermsCondition;
