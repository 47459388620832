let nodeDomain, strapiDomain, phpDomain, frontendDomain;

if (process.env.REACT_APP_ENV === "DEV") {
  nodeDomain = "https://devnodeapi.1clickpolicy.com";
  strapiDomain = "https://strapi.1clickpolicy.com";
  phpDomain = "https://devapi.1clickpolicy.com";
  frontendDomain = "https://dev.1clickpolicy.com";
} else if (process.env.REACT_APP_ENV === "STG") {
  nodeDomain = "https://stgnodeapi.1clickpolicy.com";
  strapiDomain = "https://strapi.1clickpolicy.com";
  phpDomain = "https://stgapi.1clickpolicy.com";
  frontendDomain = "https://dev.1clickpolicy.com";
} else if (process.env.REACT_APP_ENV === "PROD") {
  nodeDomain = "https://nodeapi.1clickpolicy.com";
  strapiDomain = "https://strapi.1clickpolicy.com";
  phpDomain = "https://api.1clickpolicy.com";
  frontendDomain = "https://1clickpolicy.com";
} else {
  // Default to dev environment
  nodeDomain = "https://devnodeapi.1clickpolicy.com";
  strapiDomain = "https://strapi.1clickpolicy.com";
  phpDomain = "https://devapi.1clickpolicy.com";
  frontendDomain = "https://dev.1clickpolicy.com";
}

export const NODE_DOMAIN = nodeDomain;
export const STRAPI_DOMAIN = strapiDomain;
export const PHP_DOMAIN = phpDomain;
export const FRONTEND_DOMAIN = frontendDomain;
