import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FRONTEND_DOMAIN } from "../../Routing/CommonURLs/Domain";
import { CAR_ROUTES } from "../../Routing/Path/CarRoutes";
import { CarSlice } from "../../Store/Reducer/Car/CarSlice";
import { useAppDispatch, useAppSelector } from "../../Store/Store/hooks";
import {
  FORMAT_YYYY_MM_DD,
  isEmpty,
  validateMobileNumber,
  validateRegno,
} from "../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import { ADD_CAR_DTO } from "../../Type/Car/Form/AddCarDTO";
import { TCarAddForm } from "../../Type/Car/TCarSlice/TCarSlice";
import { CAR_ADD_FORM_SERVICES } from "../../Services/Car/CarAddFormServices";
import CarProductFormPage from "../../ProductFormPage/CarProductFormPage/CarProductFormPage";

const CarProductContainer = () => {
  const isMobile = useIsMobile();
  const [carFormSteps, setCarFormSteps] = useState<
    "oldStep1" | "oldStep2" | "newStep1" | "newStep2"
  >("oldStep1");
  const [loader, setLoader] = useState<boolean>(false);
  const [vehicleInfoStatus, setVehicleInfoStatus] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const useInputChange = (initialValue: string) => {
    const [value, setValue] = useState(initialValue);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event?.target?.value);
    };

    return { value, onChange: handleChange };
  };

  const [ncbValue, changeNCB] = useState("0");
  const [value, onChange] = useState("No");

  const [formData, setFormData] = useState<TCarAddForm>(
    CarSlice.getInitialState().ADD_FORM
  );

  useEffect(() => {
    if (value === "Yes") {
      changeNCB("0");
    }
  }, [value]);

  const updateMasterState = (attrName: string, value: any) => {
    setFormData((prev: any) => {
      if (attrName === "make") {
        return {
          ...prev,
          make: { value: value, warning: isEmpty(value) },
          fuel_type: { value: "", warning: false },
          variant_cc: { value: "", warning: false },
        };
      }
      if (attrName === "model") {
        return {
          ...prev,
          model: { value: value, warning: isEmpty(value) },
          fuel_type: { value: "", warning: false },
          variant_cc: { value: "", warning: false },
        };
      }

      if (attrName === "fuel_type") {
        return {
          ...prev,
          fuel_type: { value: value, warning: isEmpty(value) },
          variant_cc: { value: "", warning: false },
        };
      }

      return {
        ...prev,
        [attrName]: {
          value: value,
          warning:
            attrName === "mobile"
              ? !validateMobileNumber(value)
              : attrName === "reg_no"
              ? !validateRegno(value)
              : isEmpty(value),
        },
      };
    });
  };

  useEffect(() => {
    setFormData((prev: any) => ({
      ...prev,
      business_type: "Rollover",
      reg_no: { value: "", warning: false },
      reg_date: { value: null, warning: false },
      make_model: { value: "", warning: false },
      fuel_type: { value: "", warning: false },
      variant_cc: { value: "", warning: false },
      rto: { value: "", warning: false },
      previous_insurer: { value: "", warning: false },
      policy_expiry_date: { value: "", warning: false },
      mobile: { value: "", warning: false },
      claimed: value,
      anti_theft_device: "No",
      automobile_association: "0",
      deductible: "0",
      make: { value: "", warning: false },
      model: { value: "", warning: false },
      pincode: { value: "", warning: false },
      owner_name: { value: "", warning: false },
      prev_ncb: "0",
      vehicle_owned: "Individual",
    }));
  }, []);

  useEffect(() => {
    GET_MAKE_LIST();
    GET_RTO_LIST();
    GET_PREV_INSURER_LIST();
  }, []);

  useEffect(() => {
    if (!isEmpty(formData.make.value) && !isEmpty(formData.model.value)) {
      GET_FUEL_TYPE_LIST();
    } else {
      dispatch(
        CarSlice.actions.SET_DROPDOWN_DATA({
          key: "FUEL_TYPE_LIST",
          value: [],
        })
      );
    }
  }, [formData.make.value, formData.model.value]);

  useEffect(() => {
    GET_VARIANT_LIST();
  }, [formData.fuel_type.value]);

  useEffect(() => {
    if (!isEmpty(formData?.make?.value)) {
      GET_MODEL_LIST();
    } else {
      dispatch(
        CarSlice.actions.SET_DROPDOWN_DATA({
          key: "MODEL_LIST",
          value: [],
        })
      );
    }
  }, [formData?.make?.value]);

  const GET_MAKE_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          make?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.make === "string") {
            data.push({ label: `${value.make}`, value: `${value.make}` });
          }
        });

        dispatch(
          CarSlice.actions.SET_DROPDOWN_DATA({
            key: "MAKE_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_MAKE_LIST(onError, onSuccess);
  };

  const GET_MODEL_LIST = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);

      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          make_model?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.make_model === "string") {
            data.push({
              label: `${value.make_model}`,
              value: `${value.make_model}`,
            });
          }
        });

        dispatch(
          CarSlice.actions.SET_DROPDOWN_DATA({
            key: "MODEL_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_MODEL_LIST(
      onError,
      onSuccess,
      `${formData.make.value}`
    );
  };

  const GET_FUEL_TYPE_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          fuel_type?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.fuel_type === "string") {
            data.push({
              label: `${value.fuel_type}`,
              value: `${value.fuel_type}`,
            });
          }
        });

        dispatch(
          CarSlice.actions.SET_DROPDOWN_DATA({
            key: "FUEL_TYPE_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_FUEL_TYPE_LIST(
      onError,
      onSuccess,
      `${formData.make.value} ${formData.model.value}`
    );
  };

  const GET_VARIANT_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          variant_cc?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.variant_cc === "string") {
            data.push({
              label: `${value.variant_cc}`,
              value: `${value.variant_cc}`,
            });
          }
        });

        dispatch(
          CarSlice.actions.SET_DROPDOWN_DATA({
            key: "VARIANT_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_VARIANT_LIST(
      onError,
      onSuccess,
      `${formData.make.value} ${formData.model.value}`,
      `${formData.fuel_type.value}`
    );
  };

  const GET_RTO_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        const data: Array<{ label: string; value: string }> = [];

        Object.entries(response).map(([brand, value]) =>
          data.push({ label: `${brand} - ${value}`, value: brand })
        );

        dispatch(
          CarSlice.actions.SET_DROPDOWN_DATA({
            key: "RTO_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_RTO_LIST(onError, onSuccess);
  };
  const GET_PREV_INSURER_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          short_name?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.short_name === "string") {
            data.push({
              label: `${value.short_name}`,
              value: `${value.short_name}`,
            });
          }
        });

        dispatch(
          CarSlice.actions.SET_DROPDOWN_DATA({
            key: "PREVIOUS_INSURER_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_PREVIOUS_INSURER_LIST(onError, onSuccess);
  };

  const GET_VEHICLE_INFO = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const response = results.response;
      const error = results.error;

      if (error === false && response) {
        const {
          make,
          make_model,
          variant_cc,
          fuel_type,
          policy_expiry_date,
          previous_insurer,
          owner_name,
        } = response;

        setFormData((prevFormData: any) => ({
          ...prevFormData,
          make: { value: make, warning: false },
          model: { value: make_model, warning: false },
          make_model: { value: make_model, warning: false },
          variant_cc: { value: variant_cc, warning: false },
          fuel_type: { value: fuel_type, warning: false },
          policy_expiry_date: { value: policy_expiry_date, warning: false },
          previous_insurer: { value: previous_insurer, warning: false },
          owner_name: { value: owner_name, warning: false },
        }));

        setVehicleInfoStatus(true);
      }
    };
    const onError = () => {};

    CAR_ADD_FORM_SERVICES.GET_VEHICLE_NO_INFO(
      onSuccess,
      onError,
      formData.reg_no.value
    );
  };

  const POST_CAR_FORM = () => {
    const onSuccess = (res: any) => {
      console.log(res);

      const quote_no = res?.response;
      const error = res?.error;
      const message = res?.message;
      setLoader(false);
      if (error === false) {
        window.location.href = `${FRONTEND_DOMAIN}${CAR_ROUTES.QUOTE}?type=CAR&quote_no=${quote_no}`;
      } else {
        toast.error(`${message}`);
      }
    };

    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    console.log("formData", formData);

    const data: ADD_CAR_DTO = {
      carBusinessType: formData.business_type,
      carClaimMade: formData.claimed,
      carFuelType: formData.fuel_type.value,
      carMakeModel: `${formData.make.value} ${formData.model.value}`,
      carMobile: formData.mobile.value,
      carPExpiryDate: FORMAT_YYYY_MM_DD(`${formData.policy_expiry_date.value}`),
      carPreviousInsurer: formData.previous_insurer.value,
      carPreviousNCB: formData.prev_ncb,
      carRegDate: FORMAT_YYYY_MM_DD(`${formData.reg_date.value}`),
      carRegNo: formData.reg_no.value,
      carRtoCode: formData.rto.value,
      carVariantCC: formData.variant_cc.value,
      // carPincode: formData.pincode.value,
    };
    setLoader(true);
    CAR_ADD_FORM_SERVICES.GENERATE_QUOTE_NO(onSuccess, onError, data);
  };

  const validateForm = () => {
    console.log("formData.business_type:", formData.business_type);
    let hasError = false;
    let data = { ...formData };
    if (carFormSteps === "oldStep2") {
      if (formData.business_type === "Rollover") {
        data = {
          ...data,
          make: {
            ...data.make,
            warning: isEmpty(data.make.value),
          },
          model: {
            ...data.model,
            warning: isEmpty(data.model.value),
          },
          fuel_type: {
            ...data.fuel_type,
            warning: isEmpty(data.fuel_type.value),
          },
          variant_cc: {
            ...data.variant_cc,
            warning: isEmpty(data.variant_cc.value),
          },
          policy_expiry_date: {
            ...data.policy_expiry_date,
            warning: isEmpty(data.policy_expiry_date.value),
          },
          previous_insurer: {
            ...data.previous_insurer,
            warning: isEmpty(data.previous_insurer.value),
          },
          owner_name: {
            ...data.owner_name,
            warning: isEmpty(data.owner_name.value),
          },
          mobile: {
            ...data.mobile,
            warning: !validateMobileNumber(data.mobile.value),
          },
        };

        if (
          data.make.warning ||
          data.model.warning ||
          data.fuel_type.warning ||
          data.variant_cc.warning ||
          data.previous_insurer.warning ||
          data.policy_expiry_date.warning ||
          data.owner_name.warning ||
          data.mobile.warning
        ) {
          hasError = true;
        }
      }
    } else if (carFormSteps === "newStep2") {
      if (formData.business_type === "New") {
        data = {
          ...data,
          make: {
            ...data.make,
            warning: isEmpty(data.make.value),
          },
          model: {
            ...data.model,
            warning: isEmpty(data.model.value),
          },
          fuel_type: {
            ...data.fuel_type,
            warning: isEmpty(data.fuel_type.value),
          },
          variant_cc: {
            ...data.variant_cc,
            warning: isEmpty(data.variant_cc.value),
          },
          owner_name: {
            ...data.owner_name,
            warning: isEmpty(data.owner_name.value),
          },
          mobile: {
            ...data.mobile,
            warning: !validateMobileNumber(data.mobile.value),
          },
        };
      }
    }
    setFormData({ ...data });
    if (
      data.make.warning ||
      data.model.warning ||
      data.fuel_type.warning ||
      data.variant_cc.warning ||
      data.owner_name.warning ||
      data.mobile.warning
    ) {
      hasError = true;
    }
    if (!hasError) {
      POST_CAR_FORM();
    }
  };
  return (
    <>
      <CarProductFormPage
        carFormSteps={carFormSteps}
        setCarFormSteps={setCarFormSteps}
        setFormData={setFormData}
        formData={formData}
        value={value}
        updateMasterState={updateMasterState}
        onChange={onChange}
        changeNCB={changeNCB}
        validateForm={validateForm}
        ncbValue={ncbValue}
      />
    </>
  );
};

export default CarProductContainer;
