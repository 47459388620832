import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subDays } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import CustomCheckbox from "../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../Component/InputFields/DatePicker/DatePicker";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import RadioText from "../../../Component/InputFields/RadioText/RadioText";
import SearchDropdown from "../../../Component/InputFields/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../Component/InputFields/SelectDropdown/SelectDropdown";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { FRONTEND_DOMAIN } from "../../../Routing/CommonURLs/Domain";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { TW_ROUTES } from "../../../Routing/Path/TWRoutes";
import { ADD_TW_DTO } from "../../../Services/DTO/TWDTO/AddTwDTO";
import { TW_ADD_FORM_SERVICES } from "../../../Services/TW/TWServices";
import { TWSlice } from "../../../Store/Reducer/TW/TwSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/Store/hooks";
import {
  FORMAT_DD_MM_YYYY,
  calculateAgeInDays,
  isEmpty,
  validateMobileNumber,
  validateRegno,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { COLORS } from "../../../SupportingFiles/colors";
import { TDropdown } from "../../../Type/Common/TDropdown";
import { TTWForm } from "../../../Type/TW/TTwSlice";
import "./../../../SCSS/ResponsivePages.scss";
import TWProductFormPage from "../../../ProductFormPage/TWProductFormPAge/TWProductFormPage";
import TWProductContainer from "../../../Container/TWProductContainer/TWProductContainer";

function TWProductPage() {
  const isMobile = useIsMobile();

  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="cmsheader">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} marginBottom={"30px"}>
            <h1 style={{ textAlign: "center" }}>Two Wheeler Insurance</h1>
          </Grid>
        </Grid>
      </Box>

      <Box className="productPages-commoncss">
        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin1 header-section"
          marginBottom={"60px"}
          alignItems={"center"}
        >
          <Grid xs={12} marginBottom={"24px"} className="mb-margin2 ">
            <h3>
              An Easy and Simple Way to gather all the information about Two
              Wheeler Insurance
            </h3>
            <p style={{ marginBottom: "16px" }}>
              A Two Wheeler Insurance is very much beneficial to get insurance
              cover for the two wheelers. It is very much helpful in covering
              all the damage, which occurs due to third-party, accident, or
              theft. In other words, 2-wheeler insurance is beneficial in
              protecting motorbike.
            </p>
          </Grid>
          <Grid xs={12} md={6} textAlign={"center"} className="mb-margin2">
            <img
              src="./images/tw-cms.svg"
              alt="insurance-policy"
              style={{
                maxWidth: "300px",
                width: "100%",
                margin: "auto",
              }}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <Box className="form-box-pp">
              <TWProductContainer />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin1"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <h2 className="mb-4">What is two wheeler Insurance?</h2>
            <p>
              As its name describes that it is the type of insurance, which is
              only beneficial for the 2-wheelers. It is very much beneficial in
              overcoming all the damage on 2-wheelers, which can be caused due
              to many reasons including road accidents, or even natural
              disasters.
            </p>
            <p>
              The Two-wheeler insurance introduced in India after the Motor
              Vehicles, 1988. The IRDAI or insurance regulatory development
              authority is the one, who take care of all the insurance products
              all across India.
            </p>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin1"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <h2 className="mb-4">
              Some of the types of the two wheeler Insurance
            </h2>
            <ul className="thingsConsiderationlist">
              <li>
                <h5>Comprehensive Cover</h5>

                <p>
                  The comprehensive policy is the one, which covers all the
                  damage on the 2-wheeler. It also cover all the damage, which
                  can be own damage or third-party damage, or by the insured
                  vehicle. Moreover, you do not get worried in the case, if your
                  vehicle got stolen as everything will be covered in this plan.
                </p>
              </li>
              <li>
                <h5>Third-party Liability Insurance</h5>
                <p>
                  Getting the third-party liability two-wheeler insurance is
                  very much important in India. It is the policy, which covers
                  all the things like loss or damage, which occurs on the
                  vehicle. Moreover, it also covers the personal accidental
                  insurance to the rider. But it does not include the cover,
                  which occurs due to theft.
                </p>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin1"
          marginBottom={"60px"}
        >
          <Grid xs={12} marginBottom={"16px"}>
            <h2 className="mb-4">What is two-wheeler comprehensive plan?</h2>
            <p className="mb-3">
              In the case, if your insurance two-wheeler damages third-party,
              which result into the death or permanent disability of the person.
              Moreover, it also includes the cover which occurs due to the loss
              of vehicle or due to theft.
            </p>
          </Grid>
          <Grid xs={12} md={6} className="mb-margin2">
            <Box className="blue-box">
              <h5 className="inclusion mb-3">
                Things covered within the two-wheeler comprehensive plan
              </h5>
              <ul>
                <li>Third-party cover</li>
                <li>Third-party property damage cover</li>
                <li>Personal accident cover</li>
                <li>Third-party liability cover</li>
                <li>Own damage</li>
              </ul>
            </Box>
          </Grid>
          <Grid xs={12} md={6}>
            <Box className="blue-box">
              <h5 className="exclusion mb-3">
                Things which are not included within two-wheeler comprehensive
                plan
              </h5>
              <ul>
                <li>Third-party cover</li>
                <li>Third-party property damage cover</li>
                <li>Personal accident cover</li>
                <li>Third-party liability cover</li>
                <li>Own damage</li>
              </ul>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin1"
          marginBottom={"60px"}
        >
          <Grid xs={12} marginBottom={"16px"}>
            <h2 className="mb-4">What is two-Wheeler Third Party Liability?</h2>
            <p className="mb-3">
              It is beneficial in the case, when the damage is caused by the
              insured bike to the other’s vehicle or on the other’s property.
              According to the Motor Vehicles Act, 1988, it is vital to have
              this insurance plan in India.
            </p>
          </Grid>
          <Grid xs={12} md={6} className="mb-margin2">
            <Box className="blue-box">
              <h5 className="inclusion mb-3">
                Things covered within the two-wheeler third party liability plan
              </h5>
              <ul>
                <li>
                  Get the benefit from the unlimited liability cover, if
                  third-party dies or got injuries.
                </li>
                <li>
                  It covers all the damage to the third-party up to Rs7.5 lakh
                </li>
                <li>
                  Money of Rs15 Lakh is provided to the person, if there will be
                  death of total disability occurs for driver or owner and all
                  the things will be covered under owner-driver personal
                  accident cover.
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid xs={12} md={6}>
            <Box className="blue-box">
              <h5 className="exclusion mb-3">
                Things, which are not included within the two-wheeler third
                party liability plan
              </h5>
              <p className=" mb-3">
                While you are going to choose the third-party liability plan,
                then it is very much important for you that you should
                understand about the exclusions of the plan
              </p>
              <ul>
                <li>
                  If the loss or damage occur due to war or any other war like
                  situation.
                </li>
                <li>
                  In the case, if any damage occur due to the usage of the
                  nuclear weapons
                </li>
                <li>
                  If a person damages his or her vehicle, then it will not
                  included within the cover.
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default TWProductPage;
