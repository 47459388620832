import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import CustomCheckbox from "../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../Component/InputFields/DatePicker/DatePicker";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import SearchDropdown from "../../../Component/InputFields/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../Component/InputFields/SelectDropdown/SelectDropdown";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { AddTermDTO } from "../../../Services/Term/TermDTO";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import { TermSlice } from "../../../Store/Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/Store/hooks";
import {
  FORMAT_DD_MM_YYYY,
  calculateDOBFromAge,
  isEmpty,
  validateMobileNumber,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TTermAddForm } from "../../../Type/Term/TTermAddForm";
import "./../../../SCSS/ResponsivePages.scss";
import TermProductContainer from "../../../Container/TermProductContainer/TermProductContainer";
// import TermSlice from "../../../Store/Reducer/Term/TermSlice";

function TermProductPage() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const { ADD_FORM } = useAppSelector((state) => state.Term);
  const { Term } = useAppSelector((state) => state);
  const [loader, setLoader] = useState<boolean>(false);

  const [formData, setFormData] = useState<TTermAddForm>(ADD_FORM);
  const dispatch = useAppDispatch();

  const annual_income_data: { label: string; value: string }[] = [];
  console.log("formDa", formData);
  // Generate labels and values for lakhs from 2 lakh to 1 crore
  for (let i = 2; i <= 100; i++) {
    const value = `${i * 100000}`;
    let label = "";
    if (i < 100) {
      label = `${i} lakh`;
    } else {
      const croreValue = i / 100;
      label = `${croreValue} crore`;
    }
    annual_income_data.push({ label, value });
  }
  const age_data: { label: string; value: string }[] = [];
  for (let i = 18; i <= 65; i++) {
    age_data.push({ label: `${i} years`, value: `${i}` });
  }
  const updateMasterState = (attrName: string, value: any) => {
    setFormData((prev: any) => ({
      ...prev,
      [attrName]: {
        value: value,
        warning:
          attrName === "pincode"
            ? !validatePincode(value)
            : attrName === "mobile"
            ? !validateMobileNumber(value)
            : isEmpty(value),
      },
    }));
  };

  const validate_form = () => {
    let hasError = false;
    let data: TTermAddForm = { ...formData };
    data = {
      ...data,
      annualIncome: {
        ...data.annualIncome,
        warning: isEmpty(data.annualIncome.value),
      },
      smoke: {
        ...data.smoke,
        warning: isEmpty(data.smoke.value),
      },
      gender: {
        ...data.gender,
        warning: isEmpty(data.gender.value),
      },
      age: {
        ...data.age,
        warning: isEmpty(data.age.value),
      },
      pincode: {
        ...data.pincode,
        warning: !validatePincode(data.pincode.value),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
    };
    if (
      data.annualIncome.warning ||
      data.smoke.warning ||
      data.gender.warning ||
      data.age.warning ||
      data.pincode.warning ||
      data.mobile.warning
    ) {
      hasError = true;
    }
    if (!hasError) {
      add_form(data);
    }
    setFormData({ ...data });
  };

  const add_form = (data: TTermAddForm) => {
    const onSuccess = (res: any) => {
      //  debugger;
      dispatch(
        TermSlice.actions.BULK_UPDATE({
          ...Term,
          ADD_FORM: { ...data },
          ADD_FORM_RESPONSE: { ...res?.results?.response },
          PROPOSER_DATA: {
            ...Term.PROPOSER_DATA,
            mobile: { value: data.mobile.value, warning: false },
            pincode: data.pincode,
          },
        })
      );

      setLoader(false);
    };
    const onError = (err: any) => {
      console.log("err", err);
      setLoader(false);
    };
    const ageInDays = parseInt(data.age.value, 10);
    const dob = !isNaN(ageInDays)
      ? FORMAT_DD_MM_YYYY(calculateDOBFromAge(ageInDays))
      : "";

    let params: AddTermDTO = {
      lifeInsurance_module: "TermPlan",
      lifeInsurance_type: "Term Plan",
      termSmoker: data.smoke.value === "Yes" ? "Y" : "N",
      termAnnualIncome: `${data.annualIncome.value}`,
      termAnnualIncomeOther: `${data.annualIncome.value}`,
      termGender: data.gender.value === "Male" ? "M" : "F",
      termDob: `${FORMAT_DD_MM_YYYY(data.age.value)}`,
      termName: data.name.value,
      termMobile: data.mobile.value,
      termPincode: data.pincode.value,
    };
    console.log("data.age.value", data.age.value);

    setLoader(true);
    TERM_SERVICES.addTermFormData(onSuccess, onError, params);
  };
  const gender_data = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  const smokinStatus_data = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="cmsheader">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} marginBottom={"30px"}>
            <h1 style={{ textAlign: "center" }}>Term Insurance</h1>
          </Grid>
        </Grid>
      </Box>

      <Box className="productPages-commoncss">
        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin1 header-section"
          marginBottom={"60px"}
          alignItems={"center"}
        >
          <Grid xs={12} marginBottom={"24px"} className="mb-margin2 ">
            <h3>TERM INSURANCE PLAN FOR FAMILY</h3>
            <p style={{ marginBottom: "8px" }}>
              Term Insurance is the finest type of life insurance policy offered
              to families by the insurers. It offers life insurance plans at
              very affordable rates in order to protect your family from any
              uncertain events of life. The term insurance policy will provide
              the beneficiary with the sum assured in case of your demise during
              the active policy period. It is the best term insurance plan for
              family because it not only provides financial aid to them, but it
              also helps in securing their future such as your child’s education
              or wedding, etc.
            </p>
            <p style={{ marginBottom: "16px" }}>
              There is a lot more to understand about the term insurance and the
              Hello policy team is here to help you out with that. After
              researching, studying and understanding all the insurance plans,
              we can assure you that a term insurance plan is the best product
              you can get because it offers maximum benefits with a minimum
              premium during the term of the policy.
            </p>
          </Grid>
          <Grid xs={12} md={6} className="mb-margin2" textAlign={"center"}>
            <img
              src="./images/term-cms.svg"
              alt="insurance-policy"
              style={{
                maxWidth: "300px",
                width: "100%",
                margin: "auto",
              }}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <Box className="form-box-pp">
              <TermProductContainer />
              {/* <Grid
                container
                spacing={2}
                textAlign="center"
                justifyContent={"center"}
              >
                <Grid xs={12}>
                  <h6
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      marginBottom: "8px",
                    }}
                  >
                    Find the best term Insurance
                  </h6>
                </Grid>
                <Grid xs={6} md={4}>
                  <SearchDropdown
                    class_name="inputField mb-2"
                    title={"Annual Income"}
                    attrName={"annualIncome"}
                    value={`${formData.annualIncome.value}`}
                    value_update={updateMasterState}
                    data={annual_income_data}
                    warn_status={formData.annualIncome.warning}
                    error_message={"Select Annual Income"}
                  />
                </Grid>
                <Grid xs={6} md={4}>
                  <SelectDropdown
                    class_name="inputField  mb-2"
                    title={"Do you smoke?"}
                    attrName={"smoke"}
                    value={formData.smoke.value}
                    value_update={updateMasterState}
                    data={smokinStatus_data}
                    warn_status={formData.smoke.warning}
                    error_message={"Select Smoker"}
                  />
                </Grid>
                <Grid xs={6} md={4}>
                  <SelectDropdown
                    class_name="inputField  mb-2"
                    title={"Your Gender"}
                    attrName={"gender"}
                    value={formData.gender.value}
                    value_update={updateMasterState}
                    data={gender_data}
                    warn_status={formData.gender.warning}
                    error_message={"Select Gender"}
                  />
                </Grid>
                <Grid xs={6} md={4}>
                  <DatePicker
                    class_name="inputField mb-2"
                    title={"Date of Birth"}
                    value={formData.age.value}
                    attrName={"age"}
                    value_update={updateMasterState}
                    warn_status={formData.age.warning}
                    error_message="Select DOB"
                    min_date={65}
                    max_date={18}
                    default_date={
                      formData.age.value === ""
                        ? subYears(new Date(), 18)
                        : subYears(new Date(), 0)
                    }
                  />
                </Grid>
                <Grid xs={6} md={4}>
                  <RKTextField
                    class_name="inputField mb-2"
                    title={"Pincode"}
                    value={formData.pincode.value}
                    attrName={"pincode"}
                    value_update={updateMasterState}
                    warn_status={formData.pincode.warning}
                    error_message={
                      isEmpty(formData.pincode.value)
                        ? "Enter Pincode"
                        : "Enter Valid Pincode"
                    }
                    max_length={6}
                    validation_type="NUMBER"
                  />
                </Grid>
                <Grid xs={6} md={4}>
                  <RKTextField
                    class_name="inputField mb-2"
                    title={"Mobile"}
                    value={formData.mobile.value}
                    attrName={"mobile"}
                    value_update={updateMasterState}
                    warn_status={formData.mobile.warning}
                    error_message={
                      isEmpty(formData.mobile.value)
                        ? "Enter Mobile Number"
                        : "Enter Valid Mobile Number"
                    }
                    max_length={10}
                    validation_type="NUMBER"
                  />
                </Grid>

                <Grid xs={12}>
                  <CustomButton
                    className="primaryBtn"
                    text="View Quotes"
                    onClick={() => {
                      validate_form();
                    }}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
                <Grid xs={12} className="popup-Checkbox">
                  <CustomCheckbox
                    checked={true}
                    // label="By clicking on view quotes you agree to all Terms & Conditions."
                    label={
                      <>
                        By clicking on view quotes you agree to all {""}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(COMMON_ROUTES.TERM_AND_CONDTION)
                          }
                        >
                          Terms & Conditions
                        </span>
                      </>
                    }
                    defaultChecked
                    value={true}
                    disabled={true}
                    attrName={"termCondition"}
                    // value={termCondition}
                    // value_update={handleCheckbox}
                  />
                </Grid>
              </Grid> */}
            </Box>
          </Grid>
          <Grid xs={12} className="mt-4">
            <p>
              The benefits and a few terms and conditions can vary from insurer
              to insurer, for instance, some companies offer coverage for
              situations wherein, the regular income of the policyholder is
              deranged due to permanent or partial disability.
            </p>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <h2 style={{ marginBottom: "12px" }}>
              What Are The Merits Of Buying Term Insurance Plan For Family?
            </h2>
            <p style={{ marginBottom: "12px" }}>
              For countless people it is hard to articulate the criticality of
              buying term insurance, so we have hand-selected the merits for
              you. We at Hellopolicy believe that it is a must to buy an
              insurance plan for everyone because its sole purpose is
              protection. There can never be enough protection for anyone in
              this world, and one can use the benefits that a term insurance
              plan for family provides.
            </p>
            <ul className="thingsConsiderationlist">
              <li>
                <h5>Coverage For Critical Illness</h5>
                <p>
                  Not only term insurance offers life cover, but it also offers
                  protection against critical illnesses such as cancer or kidney
                  failure or cardiac arrest, etc. So if you get detected with a
                  serious illness that is covered under the ride, you will get
                  paid a lump sum amount of money to avoid any type of financial
                  setbacks.
                </p>
                <p>
                  It is a possibility that your perfect looking body gives you a
                  healthy vibe, and you may think that you are fine. However, if
                  and when you fall sick, your body will become weaker and so
                  will your bank account. And that is where a term insurance
                  policy will help you with both problems.
                </p>
              </li>
              <li>
                <h5>Accidental Death Cover and Disability Support</h5>
                <p>
                  We have already established that a term insurance plan covers
                  your life, after all, it is a type of life insurance. But, if
                  you add on an accidental death cover to your existing term
                  plan, then your family will get a much bigger coverage after
                  your ultimate demise which was caused due to an accident.
                </p>
              </li>
              <li>
                <h5>Protection From Loans And Other Liabilities</h5>
                <p>
                  Well, everyone hopes of having the luxuries of life such as
                  your own home, a vehicle, etc. To avoid any type of financial
                  burden, people opt for loans. But what will happen after the
                  demise of the person who was the bread earner of the house and
                  was repaying the loan? Naturally, the burden will fall on the
                  shoulders of other family members.
                </p>
                <p>
                  A term plan will protect your family and the dependents from
                  those loans and fiscal liabilities including debts. This merit
                  is not known to all and you might not find it on other online
                  platforms, but Hellopolicy covers all aspects including
                  non-common factors.
                </p>
              </li>
              <li>
                <h5>Affordable premiums with High Sum Assured</h5>
                <p>
                  Unlike other life insurance products offered by companies,
                  term insurance plan for family, offer lower premiums. And when
                  compared with the premium, the sum assured is much higher with
                  this life insurance product. This sum assured covers many lost
                  earnings and salaries.
                </p>
                <p>
                  Now you may ask how affordable a term insurance plan is. Many
                  insurance companies such as ICICI Prudential, HDFC Life, Max
                  Life, TATA AIA, etc, offer term insurance of ₹1 Cr with a
                  premium of less than ₹1000 per month with inclusive taxes.
                </p>
              </li>
              <li>
                <h5>Tax Benefits</h5>
                <p>
                  Under Section 80C, term insurance offers a tax benefit on the
                  premium paid by the insured. And under Section 80D, additional
                  tax benefits are availed for the premium paid for the critical
                  illness cover. And at the time of claim settlement after your
                  demise, your family will receive tax benefits under section
                  10D. There are hardly any insurance policies that offer tax
                  benefits to this extent.
                </p>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <h2 style={{ marginBottom: "12px" }}>
              Other Merits of Term Insurance Plan For Family
            </h2>
            <p style={{ marginBottom: "12px" }}>
              Above mentioned merits were a bit too technical based on the
              insurance plan that you buy. However, there are some generic
              merits of a term insurance plan that will make you want to buy a
              term insurance plan right away.
            </p>
            <ul className="thingsConsiderationlist">
              <li>
                <h5>Long Term Protection</h5>
                <p>
                  We already know that term insurance covers your life and
                  protects your family from financial setbacks. But the question
                  is for how long? Well, the simple answer is, you decide the
                  duration of the term insurance plan. Depending on the plan you
                  select to be insured, you can choose a term life cover till
                  you reach the age of 50 years. Of course, this number varies
                  from company to company and policy to policy.
                </p>
              </li>
              <li>
                <h5>Payment Convenience</h5>
                <p>
                  These insurance plans are very flexible with their payments.
                  You choose to make payments on a monthly, quarterly,
                  half-yearly or on yearly basis depending on whichever is
                  convenient for you.
                </p>
              </li>
              <li>
                <h5>Cost-effective</h5>
                <p>
                  Compared to any other life insurance plan, the premiums of the
                  term insurance plan for family lower, with higher coverage.
                  This makes it the most cost-effective insurance plan of them
                  all. It does not burn a hole in your pocket instead it does
                  quite the contrary by providing a solution.
                </p>
              </li>
              <li>
                <h5>Easy To Buy</h5>
                <p>
                  Unlike earlier times, buying life insurance has become much
                  easier. In fact, gone are the days when you had to stand in a
                  queue and undergo tons of paperwork. Now you can visit the
                  company’s website online, choose the plan that suits you the
                  best and buy it, as simple as that. If you have any doubts,
                  you can go through the website and read the FAQs, reviews by
                  the customers to get real feedback, or, if you still have
                  doubts, you can dial the customer care number anytime.
                </p>
              </li>
              <li>
                <h5>Rider Benefits</h5>
                <p>
                  If term insurance is a cake, then rider benefit is the icing
                  on the cake that enhances it even more. You can opt for any
                  riders and make your term insurance plan even better than
                  before. Visit our website Hellopolicy and compare different
                  riders offered by different insurance providers. Use the
                  comparison feature and shortlist the appropriate plans in
                  order to buy the best-suited one. Just a little hint, buy an
                  insurance plan that offers riders at a lower price just like
                  the premiums to get the best out of it.
                </p>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <Box className="redlightbox">
              <Grid
                container
                columnSpacing={3}
                className="row"
                alignItems={"center"}
              >
                <Grid xs={12}>
                  <h2 style={{ marginBottom: "12px" }}>
                    Types Of Term Insurance Plan For Family In India
                  </h2>
                  <p style={{ marginBottom: "12px" }}>
                    A lot of companies in India offer term insurance plans. And
                    they all have a different set of features and benefits that
                    they offer to their customers. The world is going digital,
                    so why not insurance, which is why all the companies have
                    become flexible and proffer both online and offline purchase
                    of term insurance plans. Let us understand the different
                    types of term insurance plans.
                  </p>
                  <ul className="plans-list ">
                    <li>
                      <h5>Standard Term Insurance Plan</h5>
                      <p>
                        In a standard term insurance plan, the insured gets
                        cover against all the uncertainties and risks against
                        the premium he/she pays. This is the most popular type
                        and one of the best term insurance plans for family. And
                        among the standard term insurance, the one that requires
                        you to pay a yearly premium for an annual cover is the
                        best type of plan in standard term insurance plan.
                      </p>
                    </li>
                    <li>
                      <h5>Level Term Insurance Plan</h5>
                      <p>
                        This is one of the simplest forms of life insurance in
                        which the sum assured is fixed during the purchase and
                        remains the same throughout. After the demise of the
                        life assured, the sum assured will be received by the
                        nominee also known as the beneficiary. This life
                        insurance is offered by numerous insurance companies pan
                        India. However, there is one catch here, the size of the
                        premium depends on your age at the time of purchasing
                        the policy. The younger you are, the lower will be the
                        premium and the older you are, the higher will be the
                        premium.
                      </p>
                    </li>
                    <li>
                      <h5>Term Return Of Premium Plan</h5>
                      <p style={{ marginBottom: "8px" }}>
                        Term return of premium plan, also known as TROP plan is
                        the type of term insurance plan wherein premium paid is
                        returned in case the life assured partly survives the
                        policy tenure. This is a slightly unusual insurance
                        policy that people, especially the younger generation,
                        are interested in because it repays you (policyholder)
                        the among at the end of the tenure, that you have
                        invested in the term insurance.
                      </p>
                      <p>
                        Other than that, this insurance plan also provides the
                        policyholder with an option to add on any riders that
                        they think are necessary and can enhance their policy.
                        Just like any other policy or plan, you can add on the
                        riders, there is no other type of formality.
                      </p>
                    </li>
                    <li>
                      <h5>Group Term Insurance Plan</h5>
                      <p style={{ marginBottom: "8px" }}>
                        As the name suggests, these insurance plans are
                        specially designed for group/s of people collectively.
                        Any group of people can apply for this type of term
                        insurance such as a joint family, a large nuclear
                        family, business companies, associations, societies,
                        etc. The benefits that these term insurance policies
                        offer are the same as any other term insurance plan,
                        however, they are mostly concerned with critical
                        illnesses and other risky elements that are excluded
                        from the individual term insurance plans.
                      </p>
                      <p>
                        Apart from that, these types of term insurance policies
                        are usually not available online because the plans are
                        generally customized according to the group of people
                        and are made suitable for them.
                      </p>
                    </li>
                    <li>
                      <h5>Decreasing Term Insurance Plan</h5>
                      <p>
                        The name might have given you the idea that in
                        decreasing term insurance plans, the sum assured
                        decreases by a specified percentage every year. These
                        insurance policies typically act as mortgage clearing
                        plans to repay and clear all the debts and loans. So,
                        during the active period of the policy, if the life
                        assured dies, the sum assured will be used as the
                        repayment of the loans and debts.
                      </p>
                      <p>
                        These insurance plans not only provide financial
                        security to the family of the deceased but also provide
                        tax exemption benefits at an affordable rate.
                      </p>
                    </li>
                    <li>
                      <h5>Convertible Term Insurance Plan</h5>
                      <p>
                        Convertible term insurance plans are usually offered by
                        a few companies in India, and the reason being is their
                        flexible nature. In a convertible term insurance plan,
                        you buy an insurance policy that you find suitable but
                        you also have the option to convert into another
                        insurance plan. For instance, if you bought a standard
                        term insurance plan for 30 years, however, somewhere
                        down the line, you felt that an endowment insurance plan
                        is better suited. So, this insurance plan gives you the
                        option of converting your previous insurance plan.
                      </p>
                    </li>
                    <li>
                      <h5>Joint Life Term Insurance Plan</h5>
                      <p>
                        You might think that this is similar to buying two
                        individual term insurance plans, but let us tell you it
                        is not entirely true. A Joint life term insurance plan
                        will cost you less than buying two individual term
                        insurance plans. The benefits received in this term plan
                        is the same as the ones received in the individual plans
                        and not to forget, both the partners will get the same
                        benefits and features.
                      </p>
                      <p>
                        Normally, the people who buy these life insurance plans
                        are couples with children, where they don’t want their
                        children to worry about their future and face financial
                        setbacks in case of the demise of both parents. A joint
                        life term insurance plan also provides cover for the
                        surviving spouse or partner, and that is why this is the
                        best term insurance plan for family, especially if you
                        have a nuclear family.
                      </p>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        {/* <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
          alignItems={"center"}
        >
          <Grid xs={12}>
            <h2
              style={{ textAlign: "center", marginBottom: "40px" }}
              className="mb-margin2"
            >
              <span>Benefits of</span> Term Insurance
            </h2>
          </Grid>
          <Grid xs={12}>
            <ul className="term_ins_benefits">
              <li>
                <img src="./images/benefit_ti_icon1.svg" alt="" />
                <h6>Financial Protection to Family</h6>
                <p>
                  Offers Financial protection to policy holders family in case
                  of policy owners untimely death.
                </p>
              </li>
              <li>
                <img src="./images/benefit_ti_icon2.svg" alt="" />
                <h6>High Life cover at lower premium rates</h6>
                <p>Offers High Sum Assured at an affordable </p>
              </li>
              <li>
                <img src="./images/benefit_ti_icon3.svg" alt="" />
                <h6>Accidental Death Benefits</h6>
                <p>
                  Offers financial cover incase of permanent disability or death
                  due to an accident.
                </p>
              </li>
              <li>
                <img src="./images/benefit_ti_icon4.svg" alt="" />
                <h6>Whole Life Coverage</h6>
                <p>Provides Life coverage through out the policy term.</p>
              </li>
              <li>
                <img src="./images/benefit_ti_icon5.svg" alt="" />
                <h6>Critical Illness Coverage</h6>
                <p>Critical illness can be covered under additional riders.</p>
              </li>
              <li>
                <img src="./images/benefit_ti_icon6.svg" alt="" />
                <h6>Add on rider benefits</h6>
                <p>Provides additional coverage</p>
              </li>
              <li>
                <img src="./images/benefit_ti_icon7.svg" alt="" />
                <h6>Tax benefits</h6>
                <p>
                  Eligible for Tax benefits under Section 80C of the Income Tax
                  Act, 1961.
                </p>
              </li>
              <li>
                <img src="./images/benefit_ti_icon8.svg" alt="" />
                <h6>Return of Premium Option</h6>
                <p>
                  Policy holder receives survival benefits and receives back the
                  premium paid to the insurance company
                </p>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <h2
              style={{ textAlign: "center", marginBottom: "40px" }}
              className="mb-margin2"
            >
              <span>Elgibility Criteria for </span> Term Insurance
            </h2>
          </Grid>
          <Grid xs={12}>
            <Box className="redlightbox">
              <Grid
                container
                columnSpacing={3}
                className="row"
                alignItems={"center"}
              >
                <Grid
                  xs={12}
                  md={6}
                  textAlign={"center"}
                  className="mb-margin2"
                >
                  <img
                    src="./images/eligibility_criteria_ti.svg"
                    alt=""
                    className="ti_eligibility-img"
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <ul className="redTicklist term_redlist">
                    <li>
                      <h6>Entry Age</h6>
                      <p>Minimum 18 and Maximum 65</p>
                    </li>
                    <li>
                      <h6>Policy Period</h6>
                      <p>
                        Minimum 3-5 years depending on Insurance Company. No
                        limit for Maximum Policy Period but depends on the
                        insurance company
                      </p>
                    </li>
                    <li>
                      <h6>Who can Buy</h6>
                      <p>
                        Young Professionals, Newly Married, House Wife, Working
                        Women, Taxpayers, Parents, Senior Citizens,
                        Self-Employed People, Non-resident Indians (NRIs)
                      </p>
                    </li>
                    <li>
                      <h6>Pay-out Options</h6>
                      <p>One time amount, monthly basis or yearly basis</p>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <Box className="redborder_box">
              <h2 style={{ marginBottom: "12px" }}>
                <span> Types of </span>
                Term Policies
              </h2>
              <ul className="termPolicyType_list">
                <li>
                  <h6>Straight Term Policies</h6>
                  <p>
                    Policies designed for a specific number of years and
                    automatically terminate at the end of the period. Most
                    Insurance companies offer Five years term , Ten years term,
                    Fifteen years term and Twenty years term on a level premium
                    basis. The level premium basis means that the premium rates
                    will remain the same through out the term.
                  </p>
                </li>
                <li>
                  <h6>Long Term Policies</h6>
                  <p style={{ marginBottom: "10px" }}>
                    The Long Term polcies are of three types
                  </p>

                  <p style={{ marginBottom: "4px" }}>
                    <span style={{ fontWeight: 600 }}>
                      Term to Expectancy :
                    </span>{" "}
                    This type of policy is written for term ,ie policy holder’s
                    life expectancy.{" "}
                  </p>
                  <p style={{ marginBottom: "10px" }}>
                    For e.g : If a person has taken the policy at the age of 20
                    years and as per mortality table , expected life is 50 years
                    then the policy will expire at the age of 70.
                  </p>

                  <p style={{ marginBottom: "4px" }}>
                    <span style={{ fontWeight: 600 }}>
                      Life Expectancy Term :
                    </span>{" "}
                    This type of term policy is similar to term to expectancy
                    with an option to the policyholder to convert the term
                    policy to whole life plans. The conversion to whole life
                    plan is admissible either for a drastically reduced amount
                    of policy by continuing the premium payment at the
                    expiration of the term period or for the same amount of
                    protection by paying an increased premium.
                  </p>
                  <p style={{ marginBottom: "10px" }}>
                    For e.g : A man aged 20 buys a Rs. 1,00,000 life expectancy
                    term policy for an annual premium of Rs. 2000/- and at the
                    age of 70, he may pay the same premium and convert to whole
                    life insurance with revised Sum Assured as per table or he
                    may start paying a higher premium and continue the full
                    coverage of Rs. 1,00,000/-.
                  </p>
                  <p>
                    <span style={{ fontWeight: 600 }}>Term to age :</span> Under
                    this plan the policy can be taken up to a particular age.
                    Accordingly the policy will be termed as term to age 65 if
                    taken up to the age of 65 and term to age 70 means the
                    policy is up to the age of 70.
                  </p>
                </li>
                <li>
                  <h6>Decreasing Terms</h6>
                  <p>
                    Under this type of policy, the amount of insurance is
                    reduced each year while the premium remains the same
                    throughout the period. It is good if an individual has taken
                    a loan from the Bank or Financial Institutions.
                  </p>
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <h2 style={{ marginBottom: "24px" }} className="mb-margin1">
              <span> Limitations of </span>
              Term Insurance
            </h2>

            <ul className="limitations_list">
              <li>
                The policyholder may be uninsurable at the expiration of the
                term period and therefore unable to secure new policy. If it is
                available then the premium rate may be very high.
              </li>
              <li>
                An important use of life insurance is to provide income for old
                age but the term insurance is not having any cash values at
                retirement and cannot be used for this purpose.
              </li>
              <li>
                The disadvantage of term insurance for the average person is its
                lack of cash values, which means that if a policyholder is
                unable to meet his premium payment because of financial
                difficulties his protection will expire. There are no cash
                values out of which he can get a loan.
              </li>
            </ul>
          </Grid>
        </Grid> */}
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default TermProductPage;
