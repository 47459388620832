import { NODE_DOMAIN, PHP_DOMAIN } from "./Domain";

export const HealthURLs = {
  // ADD_HEALTH_FORM: `${PHP_DOMAIN}/api/health-insurances/add.json`,
  ADD_HEALTH_FORM: `${NODE_DOMAIN}/api/v1/health/add-form`,

  // quotation production api
  GET_QUOTATION_BY_QUOTE_ID: `${PHP_DOMAIN}/api/HealthInsurances/get-quotes.json`,

  // quotation dev api
  //GET_QUOTATION_BY_QUOTE_ID: `${PHP_DOMAIN}/api/health-insurances/get-quotes.json`,
  UPDATE_DETAIL: `${PHP_DOMAIN}/api/health-insurances/update-record.json`,
  UPDATE_SUMINSURED: `${PHP_DOMAIN}/api/health-insurances/update-sum-insured.json`,
  SEND_EMAIL_QUOTATION: `${PHP_DOMAIN}/api/health-insurances/send-email-quote-page.json`,
  GET_NETWORK_HOSPITAL_LIST: `${PHP_DOMAIN}/api/cashless-hospital-details/get-lists.json`,
  COMPARE_QUOTATION: `${PHP_DOMAIN}/api/health-insurances/compare-product.json`,
  QUOTE_BY_ACTION: `${PHP_DOMAIN}/api/buy-action-details/add.json`,
  GET_PREIUM_BY_ADDON: `${PHP_DOMAIN}/api/religares/get-addons-premium.json`,
  GET_MARITAL_DATA: `${PHP_DOMAIN}/api/product-marital-status-maps/get-list.json`,
  GET_OCCUPATION_DATA: `${PHP_DOMAIN}/api/product-occupation-maps/get-list.json`,
  GET_RELATION_DATA: `${PHP_DOMAIN}/api/product-nominee-relationship-maps/get-adult-relationship-list.json`,
  MEDICAL_DATA: `${PHP_DOMAIN}/api/health-proposals/add.json`,
  KYC_SUBMIT: `${PHP_DOMAIN}/api/ckyc-ekyc-details/go-digit.json`,
  MANUAL_KYC_SUBMIT: `${PHP_DOMAIN}/api/ckyc-ekyc-details/go-digit-upload-doc.json`,
  KYC_SUBMIT_IIFCO: `${PHP_DOMAIN}/api/ckyc-ekyc-details/iffco.json`,
  MANUAL_KYC_SUBMIT_IIFCO: `${PHP_DOMAIN}/api/ckyc-ekyc-details/iffco-upload-doc.json`,
  saveQuotesForPickUpWhereYouLeft: `${PHP_DOMAIN}/api/lowest-premium-details/add.json`,
  Capture_Record: `${PHP_DOMAIN}/api/health-proposals/captureRecord.json`,
  MAKE_PAYMENT: `${PHP_DOMAIN}/api/policies/payment-confirmation.json`,
  LOAD_QUOTATION_PWYL_HEALTH: (page: string) =>
    `${PHP_DOMAIN}/api/${page}-insurances/get-detail-by-quote.json`,
};
