import MailOutline from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import QueryBuilder from "@mui/icons-material/QueryBuilder";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import TextArea from "../../../Component/InputFields/TextArea/TextArea";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import "./../../../SCSS/ResponsivePages.scss";

function Complaint() {
  const isMobile = useIsMobile();

  const [formFields, setFormFields] = useState<{
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    message: { value: string; warn_status: boolean };
  }>({
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    message: { value: "", warn_status: false },
  });

  const updateFieldsState = (attrName: string, value: string) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="cmsheader">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} textAlign={"center"} marginBottom={"30px"}>
            <h2 style={{ textAlign: "center", marginBottom: "8px" }}>
              File a Complaint
            </h2>
            <p>Please enter details. We want to hear from you!</p>
          </Grid>
        </Grid>
      </Box>

      <Box className="form-section mb-margin1">
        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} md={6} textAlign={"center"} className="mb-margin2">
            <img
              src="./images/complaints.svg"
              alt="contact"
              className="complaint-img"
              style={{
                maxWidth:'400px',
                width:"100%",
              }}
            />

            <Box className="complaint_box">
              <h5 className="mb-3">
                For immediate assistance
                <br /> please feel free to contact our customer support.
              </h5>
              <p>
                <MailOutline />{" "}
                <a href="mailto:support@1clickpolicy.com" target="_blank">
                  support@1clickpolicy.com
                </a>
              </p>
              <p>
                <PhoneIcon />
                <a href="tel:+91 9876543210" target="_blank">
                  +91 9876543210
                </a>
              </p>
              <p>10 a.m. to 8 p.m. on all working days.</p>
            </Box>
          </Grid>
          <Grid xs={12} md={6} className="formbox">
            <RKTextField
              class_name="inputField mb-6"
              title={"Name"}
              value={formFields.name.value}
              attrName={"name"}
              value_update={updateFieldsState}
              warn_status={formFields.name.warn_status}
            />
            <RKTextField
              class_name="inputField mb-6"
              title={"Mobile"}
              value={formFields.mobile.value}
              attrName={"mobile"}
              value_update={updateFieldsState}
              warn_status={formFields.mobile.warn_status}
            />
            <RKTextField
              class_name="inputField mb-6"
              title={"Email"}
              value={formFields.email.value}
              attrName={"email"}
              value_update={updateFieldsState}
              warn_status={formFields.email.warn_status}
            />
            <TextArea
              class_name="inputField mb-6"
              title={"Please provide details to take an action?"}
              value={formFields.message.value}
              attrName={"message"}
              value_update={updateFieldsState}
              warn_status={formFields.message.warn_status}
              placeholder="Write here..."
            />
            <CustomButton
              className="primaryBtn"
              text="Submit Details"
              onClick={() => {}}
              fullWidth={false}
              variant="contained"
            />
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Complaint;
