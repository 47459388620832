import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import "./../../../SCSS/ResponsivePages.scss";
import MailOutline from "@mui/icons-material/MailOutline";

function PrivacyPolicy() {
  const isMobile = useIsMobile();
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="cmsheader tc">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} marginBottom={"24px"}>
            <h2 style={{ textAlign: "center" }}>Privacy Policy</h2>
          </Grid>
        </Grid>
      </Box>

      <Box className="textContent-pages">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} marginBottom={"24px"}>
            <h5 style={{ marginBottom: "12px" }}>Privacy Policy</h5>
            <p>
              This Privacy Policy governs the manner in which 1clickpolicy
              collects, uses, maintains and discloses information collected from
              users (each, a “User”) of the https://1clickpolicy.com/ website
              ('Site'). This privacy policy applies to the Site and all products
              and services offered by 1clickpolicy.
            </p>
          </Grid>

          <Grid xs={12} marginBottom={"24px"}>
            <h5 style={{ marginBottom: "12px" }}>
              Personal Identification Information
            </h5>
            <p>
              We may collect personal identification information from Users in a
              variety of ways, including, but not limited to, when Users visit
              our site, register on the site, place an order, subscribe to the
              newsletter, respond to a survey, fill out a form, and in
              connection with other activities, services, features or resources
              we make available on our Site. Users may be asked for, as
              appropriate, name, email address, mailing address, phone number,
              credit card information. Users may, however, visit our Site
              anonymously. We will collect personal identification information
              from Users only if they voluntarily submit such information to us.
              Users can always refuse to supply personally identification
              information, except that it may prevent them from engaging in
              certain Site related activities.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h5 style={{ marginBottom: "12px" }}>
              Non-personal Identification Information
            </h5>
            <p>
              We may collect non-personal identification information about Users
              whenever they interact with our Site. Non-personal identification
              information may include the browser name, the type of computer and
              technical information about Users means of connection to our Site,
              such as the operating system and the Internet service providers
              utilized and other similar information.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h5 style={{ marginBottom: "12px" }}>Web browser cookies</h5>
            <p>
              Our Site may use “cookies” to enhance User experience. User’s web
              browser places cookies on their hard drive for record-keeping
              purposes and sometimes to track information about them. User may
              choose to set their web browser to refuse cookies, or to alert you
              when cookies are being sent. If they do so, note that some parts
              of the Site may not function properly.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h5 style={{ marginBottom: "12px" }}>
              How we use collected information
            </h5>
            <p style={{ marginBottom: "8px" }}>
              1clickpolicy may collect and use Users personal information for
              the following purposes:
            </p>
            <ul className="content-listpoints mb-2">
              <li>
                To improve customer service Information you provide helps us
                respond to your customer service requests and support needs more
                efficiently.
              </li>
              <li>
                To personalize user experience We may use information in the
                aggregate to understand how our Users as a group use the
                services and resources provided on our Site.
              </li>
              <li>
                To improve our Site We may use feedback you provide to improve
                our products and services.
              </li>
              <li>
                To share some information (other than your personal information
                like your name, address, email address or telephone number) with
                third-party advertising companies and/or ad agencies to serve
                ads when you visit our Web site.
              </li>
              <li>
                To run a promotion, contest, survey or other Site feature To
                send Users information they agreed to receive about topics we
                think will be of interest to them.
              </li>
              <li>To send periodic emails</li>
              <li>
                If applicable, the Service Provider shall comply with the Goods
                & Services Tax (GST) regulations, ensuring that the Company
                receives all relevant benefits.
              </li>
            </ul>
            <p style={{ marginBottom: "8px" }}>
              We may use the email address to send User information and updates
              pertaining to their order.
            </p>
            <p style={{ marginBottom: "8px" }}>
              It may also be used to respond to their inquiries, questions,
              and/or other requests.
            </p>
            <p>
              If User decides to opt-in to our mailing list, they will receive
              emails that may include company news, updates, related product or
              service information, etc. If at any time the User would like to
              unsubscribe from receiving future emails, we include detailed
              unsubscribe instructions at the bottom of each email or User may
              contact us via our Site.
            </p>
          </Grid>

          <Grid xs={12} marginBottom={"24px"}>
            <h5 style={{ marginBottom: "12px" }}>
              How we protect your information
            </h5>
            <p>
              We adopt appropriate data collection, storage and processing
              practices and security measures to protect against unauthorized
              access, alteration, disclosure or destruction of your personal
              information, username, password, transaction information and data
              stored on our Site.
            </p>
            <p>
              Sensitive and private data exchange between the Site and its Users
              happens over a SSL secured communication channel and is encrypted
              and protected with digital signatures.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h5 style={{ marginBottom: "12px" }}>
              How we protect your information
            </h5>
            <p>
              We adopt appropriate data collection, storage and processing
              practices and security measures to protect against unauthorized
              access, alteration, disclosure or destruction of your personal
              information, username, password, transaction information and data
              stored on our Site.
            </p>
            <p>
              Sensitive and private data exchange between the Site and its Users
              happens over a SSL secured communication channel and is encrypted
              and protected with digital signatures.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h5 style={{ marginBottom: "12px" }}>
              Sharing your personal information
            </h5>
            <p>
              We may use third party service providers to help us operate our
              business and the Site or administer activities on our behalf, such
              as sending out newsletters or surveys. We may share your
              information with these third parties for those limited purposes
              provided that you have given us your permission.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h5 style={{ marginBottom: "12px" }}>Third party websites</h5>
            <p>
              Users may find content on our Site that links to the sites and
              services of insurance companies which may appear on our Site for
              online sale of insurance. We do not control the content or links
              that appear on these sites and are not responsible for the
              practices employed by websites linked to or from our Site. In
              addition, these sites or services, including their content and
              links, may be constantly changing. These sites and services may
              have their own privacy policies and customer service policies.
              Browsing and interaction on any other website, including websites
              which have a link to our Site, is subject to that website’s own
              terms and policies.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h5 style={{ marginBottom: "12px" }}>
              Changes to this privacy policy
            </h5>
            <p>
              1clickpolicy has the discretion to update this privacy policy at any
              time. When we do, we will revise the updated date at the bottom of
              this page and send you an email. We encourage Users to frequently
              check this page for any changes to stay informed about how we are
              helping to protect the personal information we collect. You
              acknowledge and agree that it is your responsibility to review
              this privacy policy periodically and become aware of
              modifications.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"}>
            <h5 style={{ marginBottom: "12px" }}>
              Your acceptance of these terms
            </h5>
            <p>
              By using this Site, you signify your acceptance of this policy. If
              you do not agree to this policy, please do not use our Site. Your
              continued use of the Site following the posting of changes to this
              policy will be deemed your acceptance of those changes.
            </p>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default PrivacyPolicy;
