import React, { useState } from "react";
import { Box, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import { COLORS } from "../../../SupportingFiles/colors";
import "./../../../SCSS/ResponsivePages.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";

function RefundCancellation() {
  const isMobile = useIsMobile();
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="cmsheader tc">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} marginBottom={"24px"}>
            <h2 style={{ textAlign: "center" }}>Refund & Cancellation</h2>
          </Grid>
        </Grid>
      </Box>

      <Box className="textContent-pages">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <p>
              Insurance is the subject matter of the solicitation. Details
              shared by visitors on the website may be shared with our partner
              insurance companies. The product information displayed for
              comparison is as provided by the respective insurance company with
              whom we have an agreement. The product information shared is
              authentic to the best of our knowledge and as shared by the
              insurance company.
            </p>
            <p>
              IRDA Statutory Requirement Beware of Spurious Phone Calls and
              Fictitious / Fraudulent Offers IRDAI clarifies to public that
              IRDAI or its officials do not involve in activities like sale of
              any kind of insurance or financial products nor invest premiums.
              IRDAI does not announce any bonus. Public receiving such phone
              calls are requested to lodge a police complaint along with details
              of phone call, number.
            </p>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default RefundCancellation;
