import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subDays, subYears } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import CustomCheckbox from "../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../Component/InputFields/DatePicker/DatePicker";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import SelectDropdown from "../../../Component/InputFields/SelectDropdown/SelectDropdown";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { FRONTEND_DOMAIN } from "../../../Routing/CommonURLs/Domain";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { HEALTH_ROUTE } from "../../../Routing/Path/HealthRoutes";
import { HEALTH_FORM_SERVICES } from "../../../Services/Health/HealthServices";
import { HealthSlice } from "../../../Store/Reducer/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/Store/hooks";
import {
  FORMAT_DATE_DD_MM_YYYY,
  calculateAge,
  calculateAgeInDays,
  isEmpty,
  validateMobileNumber,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { COLORS } from "../../../SupportingFiles/colors";
import { THealthAddForm } from "../../../Type/Health/THealthSlice/THealthSlice";
import "./../../../SCSS/ResponsivePages.scss";
import HealthProductContainer from "../../../Container/HealthProductContainer/HealthProductContainer";

function HealthProductPage() {
  const isMobile = useIsMobile();

  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="cmsheader">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} marginBottom={"30px"}>
            <h1 style={{ textAlign: "center" }}>Health Insurance</h1>
          </Grid>
        </Grid>
      </Box>

      <Box className="productPages-commoncss">
        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin1 header-section"
          marginBottom={"60px"}
          alignItems={"center"}
        >
          <Grid xs={12} marginBottom={"24px"} className="mb-margin2 ">
            <h3>CHEAPEST PREMIUM IN HEALTH INSURANCE - BUY BEST PLANS</h3>
            <p style={{ marginBottom: "16px" }}>
              In today's era, where medical expenses are touching the roof, it
              becomes the highest priority to find the right health insurance
              policy. Sometimes it becomes tricky to select the best policy,
              especially the one which offers maximum benefits with the cheapest
              premium in health insurance. There are a lot of insurance
              companies that understand the needs of their customers and hence
              design their plans accordingly. What usually all of us want is a
              high-sum insured health plan with affordable premiums.
            </p>
          </Grid>
          <Grid xs={12} md={6} className="mb-margin2" textAlign={"center"}>
            <img
              src="./images/health-cms.svg"
              alt="insurance-policy"
              style={{
                maxWidth: "300px",
                width: "100%",
                margin: "auto",
              }}
            />
          </Grid>
          <Grid xs={12} md={6}>
            <Box className="form-box-pp">
              <HealthProductContainer />
              {/* {healthFormSteps === "2" && (
                <Link
                  className="backstep"
                  sx={{
                    position: "absolute",
                    top: "6px",
                    left: "6px",
                    color: COLORS.black,
                    display: "inline-block",
                  }}
                  onClick={() => setHealthFormSteps("1")}
                >
                  <ChevronLeftRoundedIcon sx={{ fontSize: "30px" }} />
                </Link>
              )}

              {healthFormSteps === "1" ? (
                <Box>
                  <Grid container spacing={2} justifyContent={"center"}>
                    <Grid xs={12}>
                      <h6
                        style={{
                          textAlign: "center",
                          fontWeight: 600,
                          marginBottom: "8px",
                        }}
                      >
                        Select the family members you want to insure
                      </h6>
                    </Grid>
                    <Grid xs={6} lg={5}>
                      <Box
                        sx={{
                          border: "1px solid #BDBDBD",
                          borderRadius: "8px",
                          padding: "10px 12px 10px 8px",
                          marginBottom: "12px",
                          position: "relative",
                        }}
                        className="self-selectField"
                      >
                        <CustomCheckbox
                          label="One Adult "
                          attrName={"self_status"}
                          value={formData?.self_status}
                          isChecked={
                            !formData.spouse_status && formData.self_status
                          }
                          value_update={(newValue: any) => {
                            if (newValue) {
                              updateMasterState("spouse_status", false);
                            }
                            updateMasterState("self_status", newValue);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid xs={6} lg={5}>
                      <Box
                        sx={{
                          border: "1px solid #BDBDBD",
                          borderRadius: "8px",
                          padding: "10px 12px 10px 8px",
                          marginBottom: "12px",
                        }}
                      >
                        <CustomCheckbox
                          label="Two Adults"
                          attrName={"spouse_status"}
                          value={formData?.spouse_status}
                          isChecked={formData.spouse_status}
                          value_update={() =>
                            updateMasterState(
                              "spouse_status",
                              !formData.spouse_status
                            )
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} justifyContent={"center"}>
                    <Grid xs={12} lg={5}>
                      <Box
                        sx={{
                          border: "1px solid #BDBDBD",
                          borderRadius: "8px",
                          padding: "10px 12px 10px 8px",
                          marginBottom: "4px",
                          position: "relative",
                        }}
                      >
                        <CustomCheckbox
                          label="Son "
                          attrName={"son_status"}
                          value={formData?.son_status}
                          value_update={updateMasterState}
                          isChecked={formData?.son_status}
                          disabled={formData.daughter_count.value === 4}
                        />
                        {formData.son_status && (
                          <Box
                            position="absolute"
                            right="8px"
                            top="12px"
                            display="flex"
                            alignItems="center"
                            gap="8px"
                            zIndex="9"
                          >
                            <Box
                              onClick={() =>
                                updateMasterState("son_subtract", "")
                              }
                              height="16px"
                              width="16px"
                              color={COLORS.themeyellow}
                              textAlign="center"
                              sx={{ cursor: "pointer" }}
                            >
                              <RemoveRoundedIcon
                                sx={{ width: "16px", height: "16px" }}
                              />
                            </Box>
                            <Box
                              height="16px"
                              width="16px"
                              color={COLORS.themeyellow}
                              borderRadius="40px"
                              textAlign="center"
                              sx={{
                                backgroundColor: "rgba(255, 243, 244, 1)",
                                fontSize: "12px",
                              }}
                            >
                              {formData.son_count.value}
                            </Box>
                            <Box
                              onClick={() => updateMasterState("son_add", "")}
                              height="16px"
                              width="16px"
                              color={COLORS.themeyellow}
                              textAlign="center"
                              sx={{ cursor: "pointer" }}
                            >
                              <MailOutlineIcon
                                sx={{ width: "16px", height: "16px" }}
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                    <Grid xs={12} lg={5}>
                      <Box
                        sx={{
                          border: "1px solid #BDBDBD",
                          borderRadius: "8px",
                          padding: "10px 12px 10px 8px",
                          marginBottom: "4px",
                          position: "relative",
                        }}
                      >
                        <CustomCheckbox
                          label="Daughter "
                          attrName={"daughter_status"}
                          value={formData?.daughter_status}
                          value_update={updateMasterState}
                          isChecked={formData?.daughter_status}
                          disabled={formData.son_count.value === 4}
                        />
                        {formData.daughter_status && (
                          <Box
                            position="absolute"
                            right="8px"
                            top="12px"
                            display="flex"
                            alignItems="center"
                            gap="8px"
                            zIndex="9"
                          >
                            <Box
                              onClick={() =>
                                updateMasterState("daughter_subtract", "")
                              }
                              height="16px"
                              width="16px"
                              color={COLORS.themeyellow}
                              textAlign="center"
                              sx={{ cursor: "pointer" }}
                            >
                              <RemoveRoundedIcon
                                sx={{ width: "16px", height: "16px" }}
                              />
                            </Box>
                            <Box
                              height="16px"
                              width="16px"
                              color={COLORS.themeyellow}
                              borderRadius="40px"
                              textAlign="center"
                              sx={{
                                backgroundColor: "rgba(255, 243, 244, 1)",
                                fontSize: "12px",
                              }}
                            >
                              {formData.daughter_count.value}
                            </Box>
                            <Box
                              onClick={() =>
                                updateMasterState("daughter_add", "")
                              }
                              height="16px"
                              width="16px"
                              color={COLORS.themeyellow}
                              textAlign="center"
                              sx={{ cursor: "pointer" }}
                            >
                              <MailOutlineIcon
                                sx={{ width: "16px", height: "16px" }}
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    marginBottom="20px"
                  >
                    <Grid xs={12} marginTop="12px" textAlign={"center"}>
                      <h6 style={{ fontWeight: "500" }}>
                        Select age of your family members
                      </h6>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    marginBottom="8px"
                  >
                    <Grid xs={6}>
                      <DatePicker
                        class_name="inputField mb-2"
                        title={"1st Adult DOB"}
                        value={formData.self_dob.value}
                        attrName={"self_dob"}
                        value_update={updateMasterState}
                        warn_status={formData.self_dob.warning}
                        error_message={"Select 1st Adult DOB"}
                        min_date={110}
                        max_date={18}
                        date_validation_type="YEARS"
                        default_date={subYears(new Date(), 18)}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <SelectDropdown
                        class_name="inputField mb-2"
                        title={"Gender"}
                        attrName={"gender"}
                        value={formData.gender.value}
                        value_update={updateMasterState}
                        data={gender_data}
                        warn_status={formData.gender.warning}
                        error_message={"Select Gender"}
                      />
                    </Grid>
                  </Grid>
                  {formData.spouse_status && (
                    <>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        marginBottom="8px"
                      >
                        <Grid xs={6}>
                          <DatePicker
                            class_name="inputField mb-2"
                            title={"2nd Adult DOB"}
                            value={formData.spouse_dob.value}
                            attrName={"spouse_dob"}
                            value_update={updateMasterState}
                            warn_status={formData.spouse_dob.warning}
                            error_message={"Select 2nd Adult DOB"}
                            min_date={110}
                            max_date={18}
                            date_validation_type="YEARS"
                            default_date={subYears(new Date(), 18)}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <SelectDropdown
                            class_name="inputField mb-2"
                            title={"Relationship with 1st Adult"}
                            attrName={"spouse_relationship"}
                            value={formData?.spouse_relationship?.value}
                            value_update={updateMasterState}
                            data={relationship_data}
                            warn_status={formData.spouse_relationship.warning}
                            error_message={"Select Relationship with 1st Adult"}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {parseInt(formData.son_count.value) +
                      parseInt(formData.daughter_count.value) >=
                      1 && (
                      <Grid xs={6}>
                        <DatePicker
                          class_name="inputField mb-2"
                          title={"Child 1 DOB"}
                          value={formData?.child_one_dob?.value}
                          attrName={"child_one_dob"}
                          value_update={updateMasterState}
                          warn_status={formData?.child_one_dob?.warning}
                          error_message={"Select DOB"}
                          min_date={calculateAgeInDays(
                            FORMAT_DATE_DD_MM_YYYY(
                              `${subYears(new Date(), 25)}`
                            )
                          )}
                          max_date={91}
                          date_validation_type="DAYS"
                          default_date={subDays(new Date(), 91)}
                        />
                      </Grid>
                    )}
                    {parseInt(formData.son_count.value) +
                      parseInt(formData.daughter_count.value) >=
                      2 && (
                      <Grid xs={6}>
                        <DatePicker
                          class_name="inputField mb-2"
                          title={"Child 2 DOB"}
                          value={formData?.child_two_dob?.value}
                          attrName={"child_two_dob"}
                          value_update={updateMasterState}
                          warn_status={formData?.child_two_dob?.warning}
                          error_message={"Select DOB"}
                          min_date={calculateAgeInDays(
                            FORMAT_DATE_DD_MM_YYYY(
                              `${subYears(new Date(), 25)}`
                            )
                          )}
                          max_date={91}
                          date_validation_type="DAYS"
                          default_date={subDays(new Date(), 91)}
                        />
                      </Grid>
                    )}
                    {parseInt(formData.son_count.value) +
                      parseInt(formData.daughter_count.value) >=
                      3 && (
                      <Grid xs={6}>
                        <DatePicker
                          class_name="inputField mb-2"
                          title={"Child 3 DOB"}
                          value={formData?.child_three_dob?.value}
                          attrName={"child_three_dob"}
                          value_update={updateMasterState}
                          warn_status={formData?.child_three_dob?.warning}
                          error_message={"Select DOB"}
                          min_date={calculateAgeInDays(
                            FORMAT_DATE_DD_MM_YYYY(
                              `${subYears(new Date(), 25)}`
                            )
                          )}
                          max_date={91}
                          date_validation_type="DAYS"
                          default_date={subDays(new Date(), 91)}
                        />
                      </Grid>
                    )}
                    {parseInt(formData.son_count.value) +
                      parseInt(formData.daughter_count.value) >=
                      4 && (
                      <Grid xs={6}>
                        <DatePicker
                          class_name="inputField mb-2"
                          title={"Child 4 DOB"}
                          value={formData?.child_four_dob?.value}
                          attrName={"child_four_dob"}
                          value_update={updateMasterState}
                          warn_status={formData?.child_four_dob?.warning}
                          error_message={"Select DOB"}
                          min_date={calculateAgeInDays(
                            FORMAT_DATE_DD_MM_YYYY(
                              `${subYears(new Date(), 25)}`
                            )
                          )}
                          max_date={91}
                          date_validation_type="DAYS"
                          default_date={subDays(new Date(), 91)}
                        />
                      </Grid>
                    )}
                    <Grid xs={12} textAlign={"center"}>
                      <CustomButton
                        className="primaryBtn"
                        text="Continue"
                        onClick={
                          () => validate_form()
                          // setHealthFormSteps("2")
                        }
                        fullWidth={false}
                        variant="contained"
                      />
                    </Grid>
                  </Grid>
                </Box>
              ) : healthFormSteps === "2" ? (
                <Box>
                  <Grid
                    container
                    spacing={2}
                    textAlign="center"
                    justifyContent={"center"}
                    marginBottom={"20px"}
                  >
                    <Grid xs={12}>
                      <h6
                        style={{
                          textAlign: "center",
                          fontWeight: 500,
                          marginBottom: "8px",
                        }}
                      >
                        Fill Personal Details
                      </h6>
                    </Grid>
                    <Grid xs={8} md={6} lg={4}>
                      <RKTextField
                        class_name="inputField mb-2"
                        title={"Enter mobile number"}
                        value={formData.mobile.value}
                        attrName={"mobile"}
                        max_length={10}
                        validation_type="NUMBER"
                        value_update={updateMasterState}
                        warn_status={formData.mobile.warning}
                        error_message={
                          isEmpty(formData.mobile.value)
                            ? "Enter Mobile Number"
                            : "Enter Valid Mobile Number"
                        }
                      />
                    </Grid>
                    <Grid xs={8} md={6} lg={4}>
                      <RKTextField
                        class_name="inputField mb-2"
                        title={"Enter your pincode"}
                        value={formData.pincode.value}
                        attrName={"pincode"}
                        value_update={updateMasterState}
                        warn_status={formData.pincode.warning}
                        max_length={6}
                        validation_type="NUMBER"
                        error_message={
                          isEmpty(formData.pincode.value)
                            ? "Enter Pincode"
                            : "Enter Valid Pincode"
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    textAlign="center"
                    justifyContent={"center"}
                  >
                    <Grid xs={12}>
                      <CustomButton
                        className="primaryBtn mb-2"
                        text="View Quotes"
                        onClick={() => {
                          validate_form();
                        }}
                        fullWidth={false}
                        variant="contained"
                      />
                    </Grid>
                    <Grid xs={12} className="popup-Checkbox">
                      <CustomCheckbox
                        // label="By clicking on view quotes you agree to all Terms & Conditions."
                        label={
                          <>
                            By clicking on view quotes you agree to all {""}
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(COMMON_ROUTES.TERM_AND_CONDTION)
                              }
                            >
                              Terms & Conditions
                            </span>
                          </>
                        }
                        checked={true}
                        attrName={"termCondition"}
                        defaultChecked
                        value={true}
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ) : null} */}
            </Box>
          </Grid>
          <Grid xs={12} className="mt-4">
            <p>
              For an individual, it is often difficult to understand the
              technical terms, pricing, and coverage details. The experts of
              hello policy have conducted detailed research, considering more
              than 75 health insurance plans. This will help you to identify the
              cheapest premium in health insurance quickly.
            </p>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin1"
          marginBottom={"60px"}
        >
          <Grid xs={12} md={6} className=" mb-margin2">
            <h2 className="mb-3">Cheapest Premium in Health Insurance Plans</h2>
            <p>
              There are a lot of health insurance plans which offer the cheapest
              premium in health insurance without compromising any benefits. The
              companies have classified these plans into several categories
              which are described below -
            </p>
          </Grid>
          <Grid xs={12} md={6} textAlign={"center"}>
            <img
              src="./images/chealest-premium-img.jpg"
              alt=""
              style={{
                maxWidth: "300px",
                width: "100%",
                textAlign: "center",
              }}
            />
          </Grid>
          <Grid xs={12} marginBottom={"40px"} className="mb-margin2">
            <h4>Individual Health Insurance Plans</h4>
            <p>
              As the name clearly suggests, plans that provide benefits of the
              sum insured to a single individual. The below companies offer an
              affordable premium in health insurance across the country.
            </p>
            <ul className="plans-list mt-5">
              <li>
                <h5>HDFC Ergo Silver Smart Plan</h5>
                <p className="mb-2">
                  As per our research, HDFC Ergo - my health Sur aksha silver
                  smart is the most affordable health insurance plan for
                  individuals that offers the cheapest premium in health
                  insurance. It provides a sum insured of Rs{" "}
                  <span>3 lakhs - Rs 5 lakhs </span>. For a sum insured of Rs 5
                  lakh, it offers a premium of Rs <span> 8744 </span>
                  to a healthy man of 35 years old.
                </p>
                <p>
                  Along with the affordable premium, HDFC Ergo offers some other
                  benefits also such as there is no limit on hospital room
                  charges and if required you can get the facility of cashless
                  home treatment also. With this, the company also takes care of
                  all your medical expenses till the age of 65 years. To get a
                  stress-free recovery, the company allows you to pay the health
                  insurance premium in installments. Annual health check-ups are
                  also covered under this plan.
                </p>
              </li>
              <li>
                <h5>
                  Universal Sompo Complete Health Insurance Individual Plan
                </h5>
                <p>
                  This provides a sum insured of Rs{" "}
                  <span>3 lakhs to 5 lakhs </span> with a premium of Rs{" "}
                  <span> 6523 </span> . The salient features under Universal
                  Sompo Health Insurance Plan are somehow unique. It provides
                  treatment expenses for hospitalization and nursing homes.
                  Under this plan, surgeon costs, boarding charges, oxygen,
                  blood, and other necessary expenses are covered. Along with
                  this, the company provides a facility for inpatient dental
                  treatments and OPD expenses. If you are looking for maternity
                  expenses coverage then considering Universal Sompo is a must
                  for you.
                </p>
              </li>
              <li>
                <h5>Star Health Comprehensive Individual Plan</h5>
                <p>
                  The sum insured under this plan is Rs
                  <span>5 lakhs to Rs 1 Crore </span> , for which a policyholder
                  needs to pay the premium of Rs <span> 8277 </span>. As
                  compared to some other insurance companies, this premium with
                  such a huge sum assured can be considered as the cheapest
                  premium in health insurance. Along with the affordable
                  premium, a policyholder can avail numerous other benefits such
                  as no capping on room rents and free health checkups. You can
                  use this health plan for your dependent children of age 3
                  months to 25 years. Along with this, the star health
                  individual plan is beneficial for newborn baby covers. It also
                  provides a sum assured of Rs <span>2.5 lakh to 5 lakh </span>{" "}
                  for Bariatric Surgery.
                </p>
              </li>
            </ul>
          </Grid>
          <Grid xs={12} marginBottom={"40px"} className="mb-margin2">
            <h4>Family Floater Health Insurance Plans</h4>
            <p>
              Family Floater health insurance plans are designed to protect your
              family members. The members included under this plan are you, your
              partner, dependent parent, and dependent children of a certain age
              which vary among different companies. Your complete family will be
              covered with just a single health insurance plan. Best family
              floater health insurance plans that offer affordable premiums are
              listed below.
            </p>
            <ul className="plans-list mt-5">
              <li>
                <h5>Aditya Birla Platinum Enhanced Plan</h5>

                <p>
                  Under this plan, you will get a sum insured of Rs 2 lakh to 2
                  crores. Well, the premium changes on the basis of certain
                  factors. If a healthy male of 35 years old and his wife aged
                  34 years provides a sum assured of Rs 5 lakh then they need to
                  pay the premium of Rs 12,648. It covers all the expenses of
                  chronic illness from the beginning. Also, there is a facility
                  for a complete reload of the sum insured. With the Aditya
                  Birla Platinum plan, the policyholder does not need to worry
                  about the room rent as there is no capping on room rent.
                </p>
              </li>
              <li>
                <h5>HDFC Ergo Health Optima Restore</h5>
                <p>
                  The sum assured under this insurance plan ranges between Rs 3
                  lakhs to Rs 50 lakhs. The plan offers some excellent salient
                  features such as complete restore benefit, renewal incentives,
                  complimentary health check-ups, and daily hospital cash. With
                  just a premium of Rs 12,392, this plan comes under the
                  category of cheapest premium in health insurance. This health
                  plan covers hospitalization expenses, daycare procedures,
                  emergency road ambulance, organ donor expenses, and also there
                  is no sub-limit on room rent. This plan does not cover
                  AIDS/HIV, self-inflicted injuries, and adventure sports
                  injuries
                </p>
              </li>
              <li>
                <h5>Religare Care (Care Insurance)</h5>
                <p>
                  With a sum insured of Rs 4 lakhs to 6 crores, you will need to
                  pay the minimum premium of Rs 10,885. It offers you the
                  benefits of pre-hospitalization, in-patient hospitalization,
                  and post-hospitalization covers. Along with this, annual
                  health check-ups and lifelong renewability are its key
                  features. You will also get automatic restoration of the sum
                  insured, a private room in the hospital without any upper cap,
                  and a free second opinion.
                </p>
              </li>
            </ul>
          </Grid>
          <Grid xs={12}>
            <h4>Senior Citizen Insurance Policy</h4>
            <p>
              It is a medical insurance policy in which health coverage is
              provided to persons whose age is between 60 to 75 years. This plan
              is specifically designed by the companies to provide help to older
              individuals and provide cover for their medical emergencies. There
              are a number of benefits that almost every company offers to its
              customers. Here we are describing the top that provides the option
              for an affordable premium.
            </p>
            <ul className="plans-list mt-5">
              <li>
                <h5>Star Red Carpet Health Insurance Plan</h5>

                <p>
                  One of the perfect plans for elderly people of 60 years or
                  above. Policyholders do not need to perform any pre-screening
                  before purchasing the health insurance policy. This feature is
                  not present in many of the top insurance plans. With a wide
                  range of sum insured from Rs 1 lakh to Rs 25 lakhs, it allows
                  you to pay the lowest premium. The premium for 5 lakhs of the
                  sum is just Rs 21,240. Along with this, the star red carpet
                  health insurance plan includes features like cashless
                  hospitalization, pre and post-medical expenses, in-patient
                  hospitalization expenses, and covers modern treatments.
                </p>
              </li>
              <li>
                <h5>National Insurance Varistha Mediclaim Policy</h5>
                <p>
                  National Insurance, one of the most trusted brands in the
                  insurance sector, has been serving us for years. Its Varistha
                  health plan covers different types of medical expenses for
                  senior citizens with two major sum insured options of Rs 1
                  lakh and 2 lakhs. Again, this plan provides relief to the
                  policyholders with the cheapest premium of Rs 6,187. In this
                  plan, along with the regular covers, you will get extra
                  coverage for strokes that give permanent symptoms, severe
                  cancer, kidney failure, major organ transplant such as heart,
                  lung, liver, kidney, pancreas, etc. Also, the company provides
                  expenses for open-chest CABG. The top features of Varistha
                  mediclaim policy include free lock period, policy withdrawal,
                  grace period, cashless facility, and critical illness survival
                  period.
                </p>
              </li>
              <li>
                <h5>Oriental Individual Mediclaim Policy</h5>
                <p>
                  Oriental individual mediclaim plan comes under the category of
                  one of the affordable healthcare plan options with a sum
                  insured ranges from Rs 1 lakh to Rs 10 lakh. Along with the
                  premium of just Rs 31,257, the Oriental healthcare plan
                  provides numerous benefits to the individual. You will get
                  domiciliary hospitalization, cashless service, and other tax
                  benefits. The policy covers specified diseases or injury
                  expenses such as accidental injury, stroke, cancer, prostate,
                  knee replacement, eye diseases, hepatobiliary disorders, and
                  chronic renal failure. Along with this, a policyholder will
                  get coverage for the hospital room, ambulance charges,
                  anaesthesia, blood, oxygen, and OT charges. There are certain
                  exclusions under this plan such as surgery for eyesight
                  correction, vaccination, self-inflicted injuries, and
                  ayurvedic treatments.
                </p>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin1"
          marginBottom={"60px"}
          alignItems={"center"}
        >
          <Grid xs={12} marginBottom={"24px"}>
            <h2 className="mb-3">
              Things to Consider When Buying a Family Health Insurance
            </h2>
          </Grid>
          <Grid
            xs={12}
            md={"auto"}
            textAlign={"center"}
            className="mb-margin2"
            marginBottom={"24px"}
          >
            <img
              src="./images/affordable-premium-img.jpg"
              alt=""
              style={{
                maxWidth: "300px",
                width: "100%",
                textAlign: "center",
              }}
            />
          </Grid>
          <Grid xs={12} md marginBottom={"24px"}>
            <p>
              As you want to keep yourself and your family safe, it is always
              important to make a wise selection of a family insurance plan.
              Hellopolicy experts are here to help you. Consider the below
              points to make sure you are buying a perfect health insurance
              cover -
            </p>
          </Grid>

          <Grid xs={12}>
            <ul className="thingsConsiderationlist mt-5">
              <li>
                <h5>Coverage Under The Plan -</h5>

                <p>
                  Prepare a list of basic health coverage that different
                  policies are offering. You will find several common offers
                  such as in-hospitalization, pre-hospitalization, and
                  post-hospitalization expenses, ambulance charges, daycare
                  expenses, etc. Suppose if you are living with senior citizens
                  then you should consider a plan that provides coverage for
                  serious health issues that are usually seen in elders. On the
                  other hand, if you are planning for a baby then consider a
                  plan that covers the expenses of a new-born baby.
                </p>
              </li>
              <li>
                <h5>Flexibility For Increasing The Sum Insured -</h5>
                <p>
                  As the lifestyle is changing day by day you will see
                  fluctuation in the cost of medical treatments. There are
                  different insurance companies that offer the facility in which
                  a policyholder can request for increasing the sum insured.
                  Also, there are plans and companies which can reward you an
                  increased sum when you have no claim bonus at the time of
                  renewal of the policy. So, never forget to ask for flexibility
                  in sum insured.
                </p>
              </li>
              <li>
                <h5>Look for Maximum Renewal Age</h5>
                <p>
                  Usually, most companies offer policy renewal until 60 to 65
                  years of age while some offer the facility of lifelong
                  renewal. Make sure to consider maximum renewal age while
                  buying a health insurance plan because you want protection
                  when it is most needed. It is a general fact that with the
                  increase in age there is always an increase in risks.
                </p>
              </li>
              <li>
                <h5>Get Easy Claim Settlement</h5>
                <p>
                  It is true that all the insurance providers work as per the
                  instruction of the insurance regulator but you might find
                  differences in the way a company functions. Make sure to ask
                  about the claim settlement process, clear all your concerns
                  regarding the process with the respected insurance provider.
                  Go for a company that is offering a hassle-free claim
                  settlement process so that you will not need to file up the
                  papers. Just go to the hospital for a checkup and you will get
                  cashless treatment easily.
                </p>
              </li>
              <li>
                <h5>Check the Network of hospitals</h5>
                <p>
                  If you are attracted by the cashless hospitalization facility
                  given by any insurance company then do not just buy it
                  immediately. Make sure to check other factors also. And in
                  this list, checking the network of hospitals is crucial.
                  Choose a company that has a high number of network hospitals
                  so that at the time of emergency you can go to a nearby
                  hospital.
                </p>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" alignItems={"center"}>
          <Grid xs={12} marginBottom={"24px"}>
            <h2 className="mb-3">
              How Can You Compare Different Health Insurance Policies?
            </h2>
            <p className="mb-2">
              After comparing thousands of premium quotes,{" "}
              <span>1ClickPolicy</span> has conducted deep research and analysis
              to provide you with the exact information about different plans.
              You need to select the one that fits best with your requirement.
              Suppose if you are looking for a plan for an individual then why
              waste time on family plans. Come to <span>1ClickPolicy</span>, ask
              the experts, and we will show you a detailed picture of several
              individual plans. Make sure to calculate all your own medical
              costs and then you can pick a plan that perfectly covers your
              medical expenses.
            </p>
            <p>
              While, if you want to secure your whole family, you should choose
              a family insurance plan or a family floater plan. Don’t worry, you
              will get complete guidance at <span>1ClickPolicy</span> with a
              detailed comparison. Depending on the size of your family, you can
              select the plan which provides the{" "}
              <span>cheapest premium in health insurance</span>. If you want to
              get coverage for the immediate family then go for a family floater
              plan. While picking a family floater plan for your family make
              sure to check whether it includes coverage for parents and
              grandparents. This is because many family floater plans do not
              include any cover for grandparents and parents. Cautiously compare
              the cost and benefits of all the plans and select the best one to
              keep your family safe and healthy.
            </p>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default HealthProductPage;
