import React, { useState } from "react";
import { Box, Link, Button, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import "./NavBar.scss";
import { COLORS } from "../../../../SupportingFiles/colors";
import CustomButton from "../../../InputFields/CustomButton/CustomButton";
import LoginPopup from "./LoginPopup/LoginPopup";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";

function NavBar() {
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isMenuOpen = Boolean(anchorEl);

  return (
    <Box className="navbar">
      <LoginPopup
        open={openLoginPopup}
        handleClose={() => setOpenLoginPopup(false)}
      />
      <Grid container width="100%" columnSpacing={3} alignItems="center">
        <Grid xs={3}>
          <img
            src="./images/1clickpolicy_logo.svg"
            height="60px"
            alt="1clickpolicy"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate(COMMON_ROUTES.HOME);
            }}
            style={{ cursor: "pointer" }}
          />
        </Grid>
        <Grid xs={9}>
          <ul className="nav-list">
            <li>
              <Button
                className="nav-link product-link"
                id="basic-link"
                aria-controls={isMenuOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={isMenuOpen ? "true" : undefined}
                onClick={handleMenuClick}
                disableRipple
                sx={{
                  padding: 0,
                  color: COLORS.black,
                  fontWeight: 400,
                  textTransform: "capitalize",
                  fontSize: "14px",
                  lineHeight: "21px",
                  display: "inline-flex",
                  alignItems: "center",
                  "&:hover": {
                    background: "none",
                    color: COLORS.themeyellow,
                  },
                }}
              >
                Products{" "}
                <KeyboardArrowDownRoundedIcon sx={{ marginLeft: "4px" }} />
              </Button>
              <Menu
                className="popoverMenu"
                id="basic-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-link",
                  sx: {
                    fontSize: "14px",
                    lineHeight: "20px",
                  },
                }}
              >
                <MenuItem
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}
                >
                  Health Insurance
                </MenuItem>
                <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}>
                  Car Insurance
                </MenuItem>
                <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}>
                  Two-Wheeler Insurance
                </MenuItem>
                <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}>
                  Term Insurance
                </MenuItem>
                {/* <MenuItem>Non-Term Insurance</MenuItem> */}
              </Menu>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.ABOUT_US);
                }}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.CONTACT);
                }}
              >
                Contact
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.CLAIM);
                }}
              >
                Claim
              </Link>
            </li>
            {/* <li>
              <Link onClick={() => {}}>Face to Face</Link>
            </li> */}

            {/* <li>
              <CustomButton
                className="secondaryBtn medium"
                text="Login"
                fullWidth
                variant="contained"
                onClick={() => setOpenLoginPopup(true)}
              />
            </li> */}
          </ul>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NavBar;
